
$secondColor: #e6e6e6;

.headerBreadCrumb{
  margin-left: 15px;
}
/*
@media only screen and (min-width: 1200px) {
  .headerIcon{
    transform: rotate(180deg);
  }
}*/
