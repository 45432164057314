@import "variables";
/*高亮*/
.highlight-danger {
  color: $highlightDanger;
}

.highlight-warning {
  color: $highlightWarning;
}

.highlight-success {
  color: $highlightSuccess;
}

.highlight-primary {
  color: $highlightPrimary;
}

.highlight-info {
  color: $highlightInfo;
}

.textDecorationLineThrough{
  text-decoration: line-through;
}
/*page related*/
.searchWrapper {
  margin-bottom: 15px;
}

.paginationWrapper {
  margin-top: 15px;
}

.evenHighlightDanger {
  color: #f56c6c;
}

.evenHighlightWarning {
  color: #e6a23c;
}

.evenHighlightInfo {
  color: #909399;
}

.width33 {
  width: 33.33%;
}
.evenLeftTabs{
  width: 155px;
}
.evenForm {
  width: calc(100% - 155px);
  .ant-form-item{
    .ant-form-item-label {
      width: $labelWidth;
    }
    .ant-form-item-control {
      width: calc(100% - #{$labelWidth});
      flex-grow: 1;
      flex-basis: auto;
      flex-shrink: 1;
    }
    .paddingLeftLabelWidth {
      padding-left: $labelWidth;
    }
    .ant-input-number.width100{
      width: 100%;
    }
  }
  .reportTitle{
    text-align: center;
    font-size: 20px;
    padding: 20px;
    font-weight: bold;
    margin-bottom: 0;
  }
}

// this may cause problems
.ant-message-custom-content {
  display: flex;
  align-items: center;

  .multipleLineMessage {
    text-align: left;
  }
}

.isFileExistWrapper {
  position: relative;

  .isFileExist {
    width: 50px;
    color: $highlightDanger;
    font-size: 12px;
    font-style: italic;
    /*cursor: pointer;*/
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    /*&::before{
      width: 25px;
      content: 'vip';
      color: #409EFF;
      font-size: 12px;
      font-style: italic;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -50%;
      transform: translate(100%,0);
    }*/
  }
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.paramDetailTitle {

}

.paramDetailSubTitleWrapper {
  .paramDetailSubTitle {
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }

  .ant-form-item {
    padding-left: 40px;
  }

  .ant-form-item-label {
    width: 240px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    width: calc(100% - 240px);
    .ant-input {
      max-width: 300px;
    }
  }

  .paramDetailDesc {
    padding: 0 15px;
    color: #999;
    min-width: calc(100% - 300px);
    word-break: break-word;
  }
  .paramDetailRange{
    color: $highlightDanger;
    padding-left: 280px;
    margin-top: -20px;
  }

}

// 审核页面
.reportMenu {
  margin: 0 auto;
  line-height: 1.5;
  /*border-top: 1px solid #ddd;*/
  .title {
    text-align: center;
    font-size: 18px;
    line-height: 42px;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  .width50 {
    width: 50%;
    padding: 10px 15px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-top: none;

    &:nth-child(2n) {
      border-left: none;
    }

    &.flexWrap {
      .width40 {
        width: 40%;
        text-align: right;
      }

      .width60 {
        width: 60%;
      }
    }
  }
}

.reportParamsMenu {
  line-height: 1.5;

  .title {
    text-align: center;
    font-size: 18px;
    line-height: 42px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    /*border-top: 1px solid #ddd;*/
  }

  .flexWrap {
    div {
      padding: 10px 15px;
      border: 1px solid #ddd;
      border-top: none;

      &.width49 {
        width: 50%;
        text-align: right;
        border-right: none;
      }

      &.width50 {
        width: 50%;
      }
    }
  }
}

.reportTitle {
  text-align: center;
}
.evenUpload{
  .uploadIptWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    padding: 0 0 20px 0;

    &:hover {
      border-color: #40a9ff;
    }

    .uploadIpt {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;

      opacity: 0;
    }

    .uploadIptIconOuterWrapper {
      .uploadIptIconWrapper {
        color: #40a9ff;
        font-size: 48px;
        margin-bottom: 20px;
      }
      .uploadTxt{
        margin: 0 0 4px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }
      .uploadHint{
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  }
  .fileListItem{
    //height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-top: 8px;
    .iconWrapper{
      width: 48px;
      height: 48px;
      line-height: 54px;
      text-align: center;
      opacity: 0.8;
      font-size: 26px;
    }
    .title{
      margin-left: 8px;
    }
    .deleteIcon{
      height: 100%;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
.uploadExternalSampleOuterWrapper{
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.45);
  &.minimized{
    width: 40%;
    height: 200px;
    position: fixed;
    right: 20px;
    bottom: 0;
    .uploadExternalSampleWrapper{
      width: 100%;
      height: 100%;
      overflow-y: auto;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(0, 0);
    }
  }
  .uploadExternalSampleWrapper{
    width: 70%;
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    .uploadExternalSample{
      .title{
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.65);
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
      }
      .iconWrapper{
        >span{
          display: inline-block;
          width: 20px;
          margin-right: 6px;
          &:last-child{
            margin-right: 0;
          }
          .anticon{
            height: 100%;
            /*width: 26px;*/
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
            &:hover{
              color: rgba(0, 0, 0, 0.75);
            }
          }
        }
      }
      .main{
        padding: 24px;
      }
    }
  }
}

.uploadExternalSampleTitle{
  .iconWrapper{
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    >span{
      display: inline-block;
      width: 20px;
      margin-right: 6px;
      &:last-child{
        margin-right: 0;
      }
      .anticon{
        height: 100%;
        /*width: 26px;*/
        color: rgba(0, 0, 0, 0.45);
        cursor: pointer;
        &:hover{
          color: rgba(0, 0, 0, 0.75);
        }
      }
    }

  }
}

.uploadListTable{
  thead{
    color: rgba(0, 0, 0, 0.85);
    background-color: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    th{
      padding: 10px;
      font-weight: 500;
    }
  }
  tbody{
    tr{
      border-bottom: 1px solid #f0f0f0;
      &:hover{
        background-color: #fafafa;
      }
      td{
        padding: 10px;
      }
    }
  }
}
.processSummary{
  line-height: 20px;
  color: #999;
}
.ant-tooltip-placement-right {
  .ant-tooltip-content{
    .ant-tooltip-arrow{
      left: -13px;
    }
  }
}
.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft {
  .ant-tooltip-content{
    .ant-tooltip-arrow{
      bottom: -13px;
    }
  }
}
.fileFolderBtn{
  button{
    max-width: 100%;
    display: flex;
    align-items: center;
    span{
      display: block;
      &:nth-child(2){
        width: calc(100% - 16px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

}
.tips{
  padding-left: 110px;
  margin-bottom: 5px;
  color: #999;
  font-size: 12px;
}
.chooseBtn{
  width: 32px;
  display: inline-block;
}
.iconFont{
  display: inline-block;
  height: 32px;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  /* color: $highlightPrimary; */
  font-size: 10px;
}
