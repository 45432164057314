.excel-form-item {
  position: relative;

  .ant-checkbox-wrapper {
    position: absolute;
    top: -22px;
    left: 0;
    z-index: 9;
    visibility: hidden;
  }

  .checked {
    .ant-checkbox-wrapper {
      visibility: visible;
    }
  }

  &:hover {
    .ant-checkbox-wrapper {
      visibility: visible;
    }
  }
}