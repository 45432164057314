.sample-page {
  .search-w {
    display: flex;
    .ant-space-compact {
      width: unset;
    }
    .ant-switch {
      width: 69px;
    }
    .ant-btn {
      margin-bottom: 10px;
    }
  }

  .ant-btn-group,
  .ant-btn {
    margin-right: 10px;
  }
  .ant-btn-group .ant-btn,
  .ant-input-search .ant-btn {
    margin-right: 0;
  }
  .ant-select {
    margin-right: 10px;
    min-width: 126px;
  }
  .sample-status {
    margin-left: 10px;
  }
  .range-picker-w {
    .ant-picker-range {
      margin: 0 30px 15px 0;
      width: 400px;
    }
  }
}
